const skills = {
  languages: [
    "C++", 
    "C", 
    "Javascript",
    "Typescript",
    "Java",
    "Python",
    "Dart",
    "SQL",
    "NoSQL"
  ],
  technologies: [
    "React.js",
    "Flutter",
    "Flask",
    "Django",
    "MaterialUI",
    "Bootstrap",
    "BeautifulSoup4",
    "Selenium",
    "Jira"
  ]
}


export default skills;