const projectsList = [
  {
    name: "Statify",
    description:
      "A web app to show listening patterns across multiple timeframes with a lyric provider that matches real-time listening stream.",
    technologies: [
      "Flask",
      "Spotify Web API",
      "BeautifulSoup4",
      "Javascript",
      "Bootstrap",
    ],
    demoLink: "https://www.youtube.com/embed/_4mkh9iP5L4",
    projectGithub: "https://github.com/nilffej/Statify",
  },
  {
    name: "Spendie",
    description:
      "Spending tracker and categorizer with a calendar visual of monthly spending and spending statistics and Google Maps integration for location saving.",
    technologies: [
      "Flask",
      "Google Maps API",
      "Javascript",
      "Bootstrap",
      "SQLite",
    ],
    demoLink: "https://www.youtube.com/embed/9HhC08uPWtE",
    projectGithub: "https://github.com/jacksonzou123/moneymanager",
  },
  {
    name: "Habit Tracker",
    description:
      "Habit tracker where users log behaviors on an interactive calendar using emojis built using React.",
    technologies: ["React", "Javascript", "Bootstrap"],
    projectLink: "https://jefflin.dev/habit-tracker-eecs-493/",
    projectGithub: "https://github.com/nilffej/habit-tracker-eecs-493", 
  },
  {
    name: "Easy Clipboard",
    description:
      "Categorized clipboard for quick copying and pasting built on IndexedDB.",
    technologies: ["Javascript", "Bootstrap"],
    projectLink: "https://jefflin.dev/easy-clipboard/",
    projectGithub: "https://github.com/nilffej/easy-clipboard",
  },
  {
    name: "Handlebars",
    description:
      "Rental bike locator, bookmarker and reviewer with weather and map widgets.",
    technologies: ["Flask", "Javascript", "Bootstrap", "SQLite"],
    projectGithub: "https://github.com/nilffej/Handlebars",
  },
  {
    name: "LinkedIn Web Scraper",
    description:
      "Automated web scraper to extract education headers from profiles.",
    technologies: ["Selenium", "Python"],
    projectGithub: "https://github.com/nilffej/linkedin-scraper",
  },
];

export default projectsList;
