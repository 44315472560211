const experienceList = [
  {
    company: "OpenFin",
    role: "Engineering Intern",
    time: "May 2022 - Aug 2022",
    link: "https://www.openfin.co/",
    detailItems: [
      "Planned and developed core features and fixed functionality and UX bugs with React in primary product Workspace utilized by major leading financial institutions such as Barclays, J.P. Morgan, and Wells Fargo",
      "Designed and implemented IndexedDB data migration tool with Javascript for company domain migration initiative",
      "Led initiative to improve onboarding experience and expectations for new engineering hires",
      "Facilitated communication with UI/UX team and tech leads on implementing improved user flows",
    ],
  },
  {
    company: "Dayflect",
    role: "Mobile Development Intern",
    time: "Jan 2022 - Apr 2022",
    link: "https://www.dayflect.com/#/",
    detailItems: [
      "Developed new features with Flutter focused on increased user retention and improved user experience",
      "Designed and developed synchronization mechanism between local NoSQL database and Firestore to enable efficient local search queries on decrypted data entries",
      "Implemented automated review prompter to increase user App Store and Google Play engagement, safe account deletion to clear personal diary logs and photos, and app onboarding to enhance new user experience",
    ],
  },
];

export default experienceList;
